<template>
	<div class="animated fadeIn">
		<div class="row">
			<div class="col">
				<div
					:class="!showFilters ? 'btn-primary' : 'btn-secondary'"
					class="btn mx-1 bg-primary-alt"
					@click="showFilters = !showFilters">
					<i
						class="fa fa-filter"
						aria-hidden="true" />
				</div>
			</div>
			<div class="col">
				<b-button
					v-if="$can('push_notifications', 'add')"
					:to="{ name: 'NewPushNotification'}"
					class="float-right"
					variant="success">
					{{ translate('new') }}
				</b-button>
			</div>
		</div>
		<hr>
		<data-filter
			:display="showFilters"
			get-by="name"
			@submit="getDataFiltered"
			@clear="clearFilters">
			<template slot="form">
				<div class="row">
					<div class="col-sm">
						<div
							class="form-group">
							<label
								for="title"
								class="label">{{ translate('title') }}</label>
							<input
								id="title"
								v-model="filters.title"
								name="title"
								type="text"
								class="form-control"
								:placeholder="translate('title')">
						</div>
					</div>
					<div class="col-sm">
						<div
							class="form-group">
							<label
								for="dateRange"
								class="label">{{ translate('date_range') }}</label>
							<select
								id="dateRange"
								v-model="dateRange"
								name="dateRange"
								class="form-control">
								<option
									:value=" '' ">
									{{ translate('all') }}
								</option>
								<option
									v-for="dateRange in dateRanges"
									:key="dateRange"
									:value="dateRange">
									{{ translate(dateRange) }}
								</option>
							</select>
						</div>
					</div>
					<div
						v-if="dateRange === dateRanges.custom"
						class="col-sm">
						<label
							for="startDate"
							class="label">{{ translate('start_date') }}</label>
						<v-date-picker
							id="startDate"
							v-model="startDate"
							:input-props="{ class: 'form-control' }"
							:formats="calendarConfig"
							name="startDate"
							show-caps />
					</div>
					<div
						v-if="dateRange === dateRanges.custom"
						class="col-sm">
						<label
							for="endDate"
							class="label">{{ translate('end_date') }}</label>
						<v-date-picker
							id="endate"
							v-model="endDate"
							:input-props="{ class: 'form-control' }"
							:formats="calendarConfig"
							name="endDate"
							show-caps />
					</div>
				</div>
			</template>
		</data-filter>
		<b-row>
			<b-col>
				<div class="table-responsive">
					<table class="table table-hover table-striped text-nowrap">
						<thead>
							<tr>
								<th class="border-top-0">
									#
								</th>
								<th class="border-top-0">
									{{ translate('title') }}
								</th>
								<th class="border-top-0">
									{{ translate('text') }}
								</th>
								<th class="border-top-0">
									{{ translate('status') }}
								</th>
								<th class="border-top-0">
									{{ translate('recipients') }}
								</th>
								<th class="border-top-0">
									{{ translate('filters') }}
								</th>
								<th
									class="border-top-0 pointer"
									@click="sortByField('release_date')">
									{{ translate('release_date') }}
									<sort field="release_date" />
								</th>
								<th class="border-top-0 text-center">
									{{ translate('options') }}
								</th>
							</tr>
						</thead>
						<tbody v-if="!loading && hasData">
							<tr
								v-for="(item, index) in data"
								:key="index">
								<td class="align-middle">
									{{ ((pagination.per_page * pagination.current_page) - pagination.per_page) + (index + 1) }}
								</td>
								<td class="align-middle white-space-normal cell-custom-width">
									{{ item.attributes.title }}
								</td>
								<td class="align-middle white-space-normal cell-custom-width">
									{{ item.attributes.text }}
								</td>
								<td class="align-middle">
									{{ translate(item.attributes.status) }}
								</td>
								<td class="align-middle">
									{{ item.attributes.recipients }}
								</td>
								<td class="align-middle white-space-normal cell-custom-width">
									<div v-if="formatCountries(item.attributes.filters).length">
										<b>{{ translate('countries') }}:</b>
										<span
											:id="'tooltip' + item.id">
											<span
												v-for="(country, key) in formatCountries(item.attributes.filters, true)"
												:key="key"
												class="pointer text-primary">
												{{ translate(country.toLowerCase()) }}<span v-if="key !== formatCountries(item.attributes.filters, true).length-1">,</span><span v-else-if="formatCountries(item.attributes.filters).length > maxCountries">...</span>
											</span>
										</span>
										<b-tooltip
											:target="'tooltip' + item.id"
											placement="right"
											triggers="hover">
											<span
												v-for="(country, key) in formatCountries(item.attributes.filters)"
												:key="key">
												{{ translate(country.toLowerCase()) }}<span v-if="key !== formatCountries(item.attributes.filters).length-1">,</span>
											</span>
										</b-tooltip>
									</div>
									<div v-if="formatUsers(item.attributes.filters).length">
										<b>{{ translate('users') }}:</b>
										<span
											v-for="(user, key) in formatUsers(item.attributes.filters)"
											:key="key">
											{{ user }}<span v-if="key !== formatUsers(item.attributes.filters).length-1">,</span>
										</span>
									</div>
									<div v-if="item.attributes.geofence_name">
										<b>{{ translate('location') }}:</b>
										<span>
											{{ item.attributes.geofence_name }}
										</span>
									</div>
								</td>
								<td class="align-middle">
									{{ $moment(item.attributes.release_date.date).format(dateFormat) }}
								</td>
								<td class="align-middle text-center">
									<b-button
										v-if="cancelableStatuses.includes(item.attributes.status)"
										v-b-tooltip.hover
										:title="translate('cancel')"
										class="btn mx-1 bg-primary-alt"
										@click="cancelNotification(item.id)">
										<i
											class="fa fa-ban"
											aria-hidden="true" />
									</b-button>
									<template v-if="item.attributes.media_type !== 'none'">
										<a
											v-for="url in item.attributes.url"
											:key="url"
											v-b-tooltip.hover
											:title="translate('view_content')"
											variant="primary"
											class="btn btn-xs mr-1 bg-primary-alt"
											target="_blank"
											:href="url">
											<i class="fa fa-eye" />
										</a>
									</template>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</b-col>
		</b-row>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="hasData" />
		<b-pagination
			v-if="pagination.total_pages > 1"
			v-model="pagination.current_page"
			:total-rows="pagination.total"
			:per-page="pagination.per_page"
			align="center"
			@change="getDataFiltered" />
	</div>
</template>

<script>
import { PAGINATION } from '@/settings/RequestReply';
import { CANCELABLE_STATUSES } from '@/settings/PushNotifications';
import {
	PushNotifications as messages, Countries, Grids,
} from '@/translations';
import FiltersParams from '@/mixins/FiltersParams';
import PushNotifications from '@/util/PushNotifications';
import DataFilter from '@/components/DataFilter';
import {
	MMMDYHMS_FORMAT,
	PERIOD_FILTERS as dateRanges,
	ALL_TIME_PERIOD_FILTERS as allTimeDateRanges,
	V_CALENDAR_CONFIG as calendarConfig,
	allTime,
	custom,
} from '@/settings/Dates';

export default {
	name: 'PushNotificationsGrid',
	messages: [messages, Countries, Grids],
	components: {
		DataFilter,
	},
	mixins: [FiltersParams],
	data() {
		return {
			notification: new PushNotifications(),
			notificationCancel: new PushNotifications(),
			dateFormat: MMMDYHMS_FORMAT,
			dateRanges,
			allTimeDateRanges,
			allTime,
			custom,
			calendarConfig,
			cancelableStatuses: CANCELABLE_STATUSES,
			alert: new this.$Alert(),
			maxCountries: 4,
		};
	},
	computed: {
		loading() {
			try {
				return !!this.notification.data.loading;
			} catch (error) {
				return false;
			}
		},
		errors() {
			return this.notification.data.errors;
		},
		pagination() {
			return this.notification.data.pagination;
		},
		data() {
			try {
				const { data } = this.notification.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		hasData() {
			try {
				const response = this.data.length;
				return !!response;
			} catch (error) {
				return false;
			}
		},
	},
	mounted() {
		this.getMessages();
	},
	methods: {
		getMessages() {
			this.notification.getPushNotifications();
		},
		parseFilters(filters) {
			return JSON.parse(filters);
		},
		formatCountries(filters, hasLimit = false) {
			try {
				const countries = this.parseFilters(filters).countries.split(',');
				if (hasLimit && countries.length > this.maxCountries) {
					return countries.slice(0, this.maxCountries);
				}
				return countries;
			} catch (error) {
				return [];
			}
		},
		formatUsers(filters) {
			try {
				const users = this.parseFilters(filters).users.split(',');
				return users;
			} catch (error) {
				return [];
			}
		},
		getDataFiltered(page) {
			const { query } = this.$route;
			const options = { ...query, ...this.filters };
			if (typeof page === 'number') {
				options[PAGINATION] = page;
			}
			this.notification.getPushNotifications(options);
		},
		cancelNotification(id) {
			const trans = {
				title: this.translate('cancel_notification'),
				text: this.translate('password'),
			};
			const options = {
				buttonText: this.translate('ok'),
				cancelButtonText: this.translate('cancel'),
				emptyPasswordMessage: this.translate('password_is_required'),
			};

			this.alert.confirmationWithPassword(trans.title, trans.text, options).then((password) => {
				const payload = {
					password: password.value,
				};
				this.notificationCancel.cancelPushNotification(id, payload).then(() => {
					try {
						this.alert.toast('success', this.translate('notification_canceled'));
					} finally {
						this.notification.getPushNotifications();
					}
				}).catch(() => {
					let errorName = 'default_error_message';
					if (typeof this.notificationCancel.data.errors.errors.password !== 'undefined') {
						errorName = 'incorrect_password';
						delete this.notificationCancel.data.errors.errors.password;
					}
					this.alert.toast('error', this.translate(errorName));
				});
			}).catch(() => {});
		},
	},
};
</script>

<style scoped>
.cell-custom-width {
	min-width: 300px;
	max-width: 350px;
}
</style>
